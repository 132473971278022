<template>
    <div class="layout-horizontal">
        horizontal
        <NavBar/>
        <div class="container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue'

export default {
    components: {
        NavBar,
    },
}
</script>
