import {createRouter, createWebHistory} from 'vue-router'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";

const routes = [
    // Logout
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout.vue'),
        meta: {
            pageTitle: 'navigations.logout',
            layout: 'full',
        },
    },
    // Logout End
    // Login
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            pageTitle: 'navigations.login',
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    // Login End
    // Forgot Password
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
            pageTitle: 'navigations.forgotPassword',
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    // Forgot Password End
    // Dashboard
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Admin/Dashboard/dashboard.vue'),
      meta: {
          pageTitle: 'navigations.dashboard',
          breadcrumb: [
              {
                  text: 'navigations.dashboard',
                  active: true,
              },
          ],
      },
  },
    // Documents
    {
        path: '/documents',
        name: 'documents.list',
        component: () => import('@/views/Admin/Documents/list.vue'),
        meta: {
            permission: 'documents.list',
            pageTitle: 'navigations.documents',
            breadcrumb: [
                {
                    text: 'navigations.documents',
                    active: true,
                },
            ],
        },
    },
    // Documents
    // Directory
    {
        path: '/directory/:id',
        name: 'directory.list',
        component: () => import('@/views/Admin/Documents/directory.vue'),
        meta: {
            permission: 'documents.list',
            pageTitle: 'navigations.documents',
            breadcrumb: [
                {
                    text: 'navigations.documents',
                    active: true,
                },
            ],
        },
    },
    // Directory
    // Stations
    {
        path: '/settings/stations',
        name: 'Stations',
        component: () => import('@/views/Admin/Settings/Stations/list.vue'),
        meta: {
            permission: 'station.list',
            pageTitle: 'navigations.station',
            breadcrumb: [
                {
                    text: 'navigations.station',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/stations/add',
        name: 'AddStation',
        component: () => import('@/views/Admin/Settings/Stations/add.vue'),
        meta: {
            permission: 'station.create',
            pageTitle: 'messages.stationCreate',
            breadcrumb: [
                {
                    text: 'messages.stationCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/stations/:id/edit',
        name: 'EditStation',
        component: () => import('@/views/Admin/Settings/Stations/edit.vue'),
        meta: {
            permission: 'station.view',
            pageTitle: 'messages.stationUpdate',
            breadcrumb: [
                {
                    text: 'messages.stationUpdate',
                    active: true,
                },
            ],
        },
    },
    // Stations end
    // Departments
    {
        path: '/settings/departments',
        name: 'Departments',
        component: () => import('@/views/Admin/Settings/Departments/list.vue'),
        meta: {
            permission: 'department.list',
            pageTitle: 'navigations.department',
            breadcrumb: [
                {
                    text: 'navigations.department',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/departments/add',
        name: 'AddDepartment',
        component: () => import('@/views/Admin/Settings/Departments/add.vue'),
        meta: {
            permission: 'department.create',
            pageTitle: 'messages.departmentCreate',
            breadcrumb: [
                {
                    text: 'messages.departmentCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/departments/:id/edit',
        name: 'EditDepartment',
        component: () => import('@/views/Admin/Settings/Departments/edit.vue'),
        meta: {
            permission: 'department.view',
            pageTitle: 'messages.departmentUpdate',
            breadcrumb: [
                {
                    text: 'messages.departmentUpdate',
                    active: true,
                },
            ],
        },
    },
    // Departments end
    // Banners
    {
      path: '/settings/banners',
      name: 'Banners',
      component: () => import('@/views/Admin/Settings/Banners/list.vue'),
      meta: {
          permission: 'banners.list',
          pageTitle: 'navigations.banners',
          breadcrumb: [
              {
                  text: 'navigations.banners',
                  active: true,
              },
          ],
      },
  },
  {
      path: '/settings/banners/add',
      name: 'AddBanner',
      component: () => import('@/views/Admin/Settings/Banners/add.vue'),
      meta: {
          permission: 'banners.create',
          pageTitle: 'messages.bannerCreate',
          breadcrumb: [
              {
                  text: 'messages.bannerCreate',
                  active: true,
              },
          ],
      },
  },
  {
      path: '/settings/banners/:id/edit',
      name: 'EditBanner',
      component: () => import('@/views/Admin/Settings/Banners/edit.vue'),
      meta: {
          permission: 'banners.view',
          pageTitle: 'messages.bannerUpdate',
          breadcrumb: [
              {
                  text: 'messages.bannerUpdate',
                  active: true,
              },
          ],
      },
  },
  // Banners end
    // Exports
    {
        path: '/exports',
        name: 'Exports',
        component: () => import('@/views/Admin/Exports/index.vue'),
        meta: {
            permission: 'exports.list',
            pageTitle: 'navigations.exports',
            breadcrumb: [
                {
                    text: 'navigations.exports',
                    active: true,
                },
            ],
        },
    },
    // Exports end
    // Part requests
    {
        path: '/part-requests',
        name: 'PartRequests',
        component: () => import('@/views/Admin/Forms/PartRequests/list.vue'),
        meta: {
            permission: 'part_requests.list',
            pageTitle: 'navigations.partRequests',
            breadcrumb: [
                {
                    text: 'navigations.partRequests',
                    active: true,
                },
            ],
        },
    },
    // Part requests end
    // AC Types
    {
        path: '/settings/ac-types',
        name: 'AC Types',
        component: () => import('@/views/Admin/Forms/AcTypes/list.vue'),
        meta: {
            permission: 'ac_types.list',
            pageTitle: 'navigations.acTypes',
            breadcrumb: [
                {
                    text: 'navigations.acTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/ac-types/add',
        name: 'AddAcType',
        component: () => import('@/views/Admin/Forms/AcTypes/add.vue'),
        meta: {
            permission: 'ac_types.create',
            pageTitle: 'messages.acTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.acTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/ac-types/:id/edit',
        name: 'EditAcType',
        component: () => import('@/views/Admin/Forms/AcTypes/edit.vue'),
        meta: {
            permission: 'ac_types.view',
            pageTitle: 'messages.acTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.acTypeUpdate',
                    active: true,
                },
            ],
        },
    },
    // AC Types end
    // Form Types
    {
        path: '/settings/form-types',
        name: 'Form Types',
        component: () => import('@/views/Admin/Forms/FormTypes/list.vue'),
        meta: {
            permission: 'form_types.list',
            pageTitle: 'navigations.formTypes',
            breadcrumb: [
                {
                    text: 'navigations.formTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/form-types/add',
        name: 'AddFormType',
        component: () => import('@/views/Admin/Forms/FormTypes/add.vue'),
        meta: {
            permission: 'form_types.create',
            pageTitle: 'messages.formTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.formTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/form-types/:id/edit',
        name: 'EditFormType',
        component: () => import('@/views/Admin/Forms/FormTypes/edit.vue'),
        meta: {
            permission: 'form_types.view',
            pageTitle: 'messages.formTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.formTypeUpdate',
                    active: true,
                },
            ],
        },
    },
    // Form types end
    // Service request types
    {
        path: '/settings/sr-types',
        name: 'SrTypes',
        component: () => import('@/views/Admin/Forms/ServiceRequestTypes/list.vue'),
        meta: {
            permission: 'service_requests.list',
            pageTitle: 'navigations.srTypes',
            breadcrumb: [
                {
                    text: 'navigations.srTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/sr-types/add',
        name: 'AddSrType',
        component: () => import('@/views/Admin/Forms/ServiceRequestTypes/add.vue'),
        meta: {
            permission: 'service_requests.create',
            pageTitle: 'messages.srTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.srTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/sr-types/:id/edit',
        name: 'EditSrType',
        component: () => import('@/views/Admin/Forms/ServiceRequestTypes/edit.vue'),
        meta: {
            permission: 'service_requests.view',
            pageTitle: 'messages.srTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.srTypeUpdate',
                    active: true,
                },
            ],
        },
    },
    // Service request types end
    // CSR Stamps
    {
        path: '/settings/csr-stamps',
        name: 'CsrStamps',
        component: () => import('@/views/Admin/Forms/CsrStamps/list.vue'),
        meta: {
            permission: 'csr_stamps.list',
            pageTitle: 'navigations.csrStamps',
            breadcrumb: [
                {
                    text: 'navigations.csrStamps',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/csr-stamps/add',
        name: 'AddCsrStamp',
        component: () => import('@/views/Admin/Forms/CsrStamps/add.vue'),
        meta: {
            permission: 'csr_stamps.create',
            pageTitle: 'messages.csrStampCreate',
            breadcrumb: [
                {
                    text: 'messages.csrStampCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/csr-stamps/:id/edit',
        name: 'EditCsrStamps',
        component: () => import('@/views/Admin/Forms/CsrStamps/edit.vue'),
        meta: {
            permission: 'csr_stamps.view',
            pageTitle: 'messages.csrStampUpdate',
            breadcrumb: [
                {
                    text: 'messages.csrStampUpdate',
                    active: true,
                },
            ],
        },
    },
    // CSR Stamps end
    // Forms
    {
        path: '/forms',
        name: 'Forms',
        component: () => import('@/views/Admin/Forms/MainForms/list.vue'),
        meta: {
            permission: 'forms.list',
            pageTitle: 'navigations.mainForms',
            breadcrumb: [
                {
                    text: 'navigations.mainForms',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/forms/add/:id',
        name: 'FormsAdd',
        component: () => import('@/views/Admin/Forms/MainForms/add.vue'),
        meta: {
            permission: 'forms.create',
            pageTitle: 'messages.formCreate',
            breadcrumb: [
                {
                    text: 'messages.formCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/forms/:id/view',
        name: 'FormsView',
        component: () => import('@/views/Admin/Forms/MainForms/view.vue'),
        meta: {
            permission: 'forms.view',
            pageTitle: 'messages.formUpdate',
            breadcrumb: [
                {
                    text: 'messages.formUpdate',
                    active: true,
                },
            ],
        },
    },
    // Forms end
    // Branches
    {
        path: '/settings/branches',
        name: 'Branches',
        component: () => import('@/views/Admin/Settings/Branches/list.vue'),
        meta: {
            permission: 'branch.list',
            pageTitle: 'navigations.branch',
            breadcrumb: [
                {
                    text: 'navigations.branch',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/branches/add',
        name: 'AddBranch',
        component: () => import('@/views/Admin/Settings/Branches/add.vue'),
        meta: {
            permission: 'branch.create',
            pageTitle: 'messages.holidayCreate',
            breadcrumb: [
                {
                    text: 'messages.holidayCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/branches/:id/edit',
        name: 'EditBranch',
        component: () => import('@/views/Admin/Settings/Branches/edit.vue'),
        meta: {
            permission: 'branch.view',
            pageTitle: 'messages.branchUpdate',
            breadcrumb: [
                {
                    text: 'messages.branchUpdate',
                    active: true,
                },
            ],
        },
    },
    // Branches end
    // Projects
    {
        path: '/projects',
        name: 'Projects',
        component: () => import('@/views/Admin/TimeTracking/Projects/list.vue'),
        meta: {
            permission: 'projects.list',
            pageTitle: 'navigations.projects',
            breadcrumb: [
                {
                    text: 'navigations.projects',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/projects/add',
        name: 'AddProject',
        component: () => import('@/views/Admin/TimeTracking/Projects/add.vue'),
        meta: {
            permission: 'projects.create',
            pageTitle: 'messages.projectCreate',
            breadcrumb: [
                {
                    text: 'messages.projectCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/projects/:id/edit',
        name: 'EditProject',
        component: () => import('@/views/Admin/TimeTracking/Projects/edit.vue'),
        meta: {
            permission: 'projects.view',
            pageTitle: 'messages.projectUpdate',
            breadcrumb: [
                {
                    text: 'messages.projectUpdate',
                    active: true,
                },
            ],
        },
    },
    // Projects end
    // Time tracker
    {
        path: '/time-tracking',
        name: 'Time tracker',
        component: () => import('@/views/Admin/TimeTracking/TimeTracker/list.vue'),
        meta: {
            permission: 'time_tracking.list',
            pageTitle: 'navigations.timeTracker',
            breadcrumb: [
                {
                    text: 'navigations.timeTracker',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/time-tracking/add',
        name: 'AddTimeTracker',
        component: () => import('@/views/Admin/TimeTracking/TimeTracker/add.vue'),
        meta: {
            permission: 'time_tracking.create',
            pageTitle: 'messages.timeTrackerCreate',
            breadcrumb: [
                {
                    text: 'messages.timeTrackerCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/time-tracking/:id/edit',
        name: 'EditTimeTracker',
        component: () => import('@/views/Admin/TimeTracking/TimeTracker/edit.vue'),
        meta: {
            permission: 'time_tracking.view',
            pageTitle: 'messages.timeTrackerUpdate',
            breadcrumb: [
                {
                    text: 'messages.timeTrackerUpdate',
                    active: true,
                },
            ],
        },
    },
    // Time tracker end
    // Invoice categories end
    {
        path: '/invoices/categories',
        name: 'InvoiceCategories',
        component: () => import('@/views/Admin/Invoices/InvoiceCategories/list.vue'),
        meta: {
            permission: 'invoice_categories.list',
            pageTitle: 'navigations.categories',
            breadcrumb: [
                {
                    text: 'navigations.categories',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/invoices/categories/add',
        name: 'InvoiceCategoriesAdd',
        component: () => import('@/views/Admin/Invoices/InvoiceCategories/add.vue'),
        meta: {
            permission: 'invoice_categories.create',
            pageTitle: 'messages.invoiceCategoryCreate',
            breadcrumb: [
                {
                    text: 'messages.invoiceCategoryCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/invoices/categories/:id/edit',
        name: 'InvoiceCategoriesEdit',
        component: () => import('@/views/Admin/Invoices/InvoiceCategories/edit.vue'),
        meta: {
            permission: 'invoice_categories.view',
            pageTitle: 'messages.invoiceCategoryUpdate',
            breadcrumb: [
                {
                    text: 'messages.invoiceCategoryUpdate',
                    active: true,
                },
            ],
        },
    },
    // Invoice categories end
    // Outgoing invoices
    {
        path: '/invoices/outgoing',
        name: 'Outgoing invoices',
        component: () => import('@/views/Admin/Invoices/outgoing.vue'),
        meta: {
            permission: 'outgoing_invoices.list',
            pageTitle: 'navigations.outgoingInvoices',
            breadcrumb: [
                {
                    text: 'navigations.outgoingInvoices',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/invoices/outgoing/add',
        name: 'AddInoviceOutgoing',
        component: () => import('@/views/Admin/Invoices/add.vue'),
        meta: {
            permission: 'outgoing_invoices.create',
            pageTitle: 'messages.invoiceCreate',
            breadcrumb: [
                {
                    text: 'messages.invoiceCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/invoices/outgoing/:id/edit',
        name: 'EditInvoiceOutgoing',
        component: () => import('@/views/Admin/Invoices/edit.vue'),
        meta: {
            permission: 'outgoing_invoices.view',
            pageTitle: 'messages.invoiceUpdate',
            breadcrumb: [
                {
                    text: 'messages.invoiceUpdate',
                    active: true,
                },
            ],
        },
    },

    // Outgoing invoices end
    // Incoming invoices
    {
        path: '/invoices/incoming',
        name: 'Incoming invoices',
        component: () => import('@/views/Admin/Invoices/incoming.vue'),
        meta: {
            permission: 'incoming_invoices.list',
            pageTitle: 'navigations.incomingInvoices',
            breadcrumb: [
                {
                    text: 'navigations.incomingInvoices',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/invoices/incoming/add',
        name: 'AddInoviceIncoming',
        component: () => import('@/views/Admin/Invoices/add.vue'),
        meta: {
            permission: 'incoming_invoices.create',
            pageTitle: 'messages.invoiceCreate',
            breadcrumb: [
                {
                    text: 'messages.invoiceCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/invoices/incoming/:id/edit',
        name: 'EditInvoiceIncoming',
        component: () => import('@/views/Admin/Invoices/edit.vue'),
        meta: {
            permission: 'incoming_invoices.view',
            pageTitle: 'messages.invoiceUpdate',
            breadcrumb: [
                {
                    text: 'messages.invoiceUpdate',
                    active: true,
                },
            ],
        },
    },

    // Incoming invoices end
    // Statistics
    {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('@/views/Admin/TimeTracking/Statistics/list.vue'),
        meta: {
            permission: 'statistics.list',
            pageTitle: 'navigations.statistics',
            breadcrumb: [
                {
                    text: 'navigations.statistics',
                    active: true,
                },
            ],
        },
    },
    // Statistics end
    // Holiday requests
    {
        path: '/holiday-requests',
        name: 'HolidayRequests',
        component: () => import('@/views/Admin/HolidayRequests/list.vue'),
        meta: {
            permission: 'holiday_requests.list',
            pageTitle: 'navigations.holiday',
            breadcrumb: [
                {
                    text: 'navigations.holiday',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/holiday-requests/add',
        name: 'AddHolidayRequest',
        component: () => import('@/views/Admin/HolidayRequests/add.vue'),
        meta: {
            permission: 'holiday_requests.create',
            pageTitle: 'messages.holidayCreate',
            breadcrumb: [
                {
                    text: 'messages.holidayCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/holiday-requests/:id/edit',
        name: 'EditHolidayRequest',
        component: () => import('@/views/Admin/HolidayRequests/edit.vue'),
        meta: {
            permission: 'holiday_requests.view',
            pageTitle: 'messages.holidayUpdate',
            breadcrumb: [
                {
                    text: 'messages.holidayUpdate',
                    active: true,
                },
            ],
        },

    },
    // Holiday requests End
    // Travelling orders
    {
        path: '/travelling-orders',
        name: 'TravellingOrders',
        component: () => import('@/views/Admin/TravellingOrders/list.vue'),
        meta: {
            permission: 'travelling_orders.list',
            pageTitle: 'navigations.travellingOrders',
            breadcrumb: [
                {
                    text: 'navigations.travellingOrders',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/travelling-orders/add',
        name: 'AddTravellingOrder',
        component: () => import('@/views/Admin/TravellingOrders/add.vue'),
        meta: {
            permission: 'travelling_orders.create',
            pageTitle: 'messages.travellingOrdersCreate',
            breadcrumb: [
                {
                    text: 'messages.travellingOrdersCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/travelling-orders/:id/edit',
        name: 'EditTravelingOrder',
        component: () => import('@/views/Admin/TravellingOrders/edit.vue'),
        meta: {
            permission: 'travelling_orders.view',
            pageTitle: 'messages.travellingOrdersUpdate',
            breadcrumb: [
                {
                    text: 'messages.travellingOrdersUpdate',
                    active: true,
                },
            ],
        },

    },
    // Travelling orders End
    // Read and understand
    {
        path: '/read-and-understand',
        name: 'ReadAndUnderstand',
        component: () => import('@/views/Admin/ReadAndUnderstand/list.vue'),
        meta: {
            permission: 'read_and_understand.list',
            pageTitle: 'navigations.read',
            breadcrumb: [
                {
                    text: 'navigations.read',
                    active: true,
                },
            ],
        },
    },
    // Read and understand End
    // Employees
    {
        path: '/employees',
        name: 'Employees',
        component: () => import('@/views/Admin/Employees/list.vue'),
        meta: {
            permission: 'employees.list',
            pageTitle: 'navigations.employees',
            breadcrumb: [
                {
                    text: 'navigations.employees',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employees/add',
        name: 'AddEmployee',
        component: () => import('@/views/Admin/Employees/add.vue'),
        meta: {
            permission: 'employees.create',
            pageTitle: 'messages.employeeCreate',
            breadcrumb: [
                {
                    text: 'messages.employeeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employees/:id/edit',
        name: 'EditEmployee',
        component: () => import('@/views/Admin/Employees/edit.vue'),
        meta: {
            permission: 'employees.view',
            pageTitle: 'messages.employeeUpdate',
            breadcrumb: [
                {
                    text: 'messages.employeeUpdate',
                    active: true,
                },
            ],
        },

    },
    // Employees End
    // Customers
    {
        path: '/customers',
        name: 'Customers',
        component: () => import('@/views/Admin/Customers/list.vue'),
        meta: {
            permission: 'customers.list',
            pageTitle: 'navigations.customers',
            breadcrumb: [
                {
                    text: 'navigations.customers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/customers/add',
        name: 'AddCustomer',
        component: () => import('@/views/Admin/Customers/add.vue'),
        meta: {
            permission: 'customers.create',
            pageTitle: 'messages.customerCreate',
            breadcrumb: [
                {
                    text: 'messages.customerCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/customers/:id/edit',
        name: 'EditCustomer',
        component: () => import('@/views/Admin/Customers/edit.vue'),
        meta: {
            permission: 'customers.view',
            pageTitle: 'messages.customerUpdate',
            breadcrumb: [
                {
                    text: 'messages.customerUpdate',
                    active: true,
                },
            ],
        },

    },
    // Employees End
    // Emails
    {
        path: '/emails',
        name: 'Emails',
        component: () => import('@/views/Admin/Emails/list.vue'),
        meta: {
            permission: 'emails.list',
            pageTitle: 'navigations.emails',
            breadcrumb: [
                {
                    text: 'navigations.emails',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/emails/:id/view',
        name: 'ViewEmails',
        component: () => import('@/views/Admin/Emails/detail.vue'),
        meta: {
            permission: 'emails.view',
            pageTitle: 'messages.viewEmail',
            breadcrumb: [
                {
                    text: 'messages.viewEmail',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/emails/send',
        name: 'SendEmail',
        component: () => import('@/views/Admin/Emails/send.vue'),
        meta: {
            permission: 'emails.create',
            pageTitle: 'messages.sendEmail',
            breadcrumb: [
                {
                    text: 'messages.sendEmail',
                    active: true,
                },
            ],
        },
    },
    // Emails End
    // Receipts
    {
        path: '/receipts',
        name: 'Receipts',
        component: () => import('@/views/Admin/Receipts/list.vue'),
        meta: {
            permission: 'receipts.list',
            pageTitle: 'navigations.receipts',
            breadcrumb: [
                {
                    text: 'navigations.receipts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/receipts/add',
        name: 'AddReceipt',
        component: () => import('@/views/Admin/Receipts/add.vue'),
        meta: {
            permission: 'receipts.create',
            pageTitle: 'messages.ReceiptsCreate',
            breadcrumb: [
                {
                    text: 'messages.ReceiptsCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/receipts/:id/edit',
        name: 'EditReceipt',
        component: () => import('@/views/Admin/Receipts/edit.vue'),
        meta: {
            permission: 'receipts.view',
            pageTitle: 'messages.receiptsUpdate',
            breadcrumb: [
                {
                    text: 'messages.receiptsUpdate',
                    active: true,
                },
            ],
        },

    },
    // Receipts End
    // Stock items
    {
        path: '/stock-items',
        name: 'StockItems',
        component: () => import('@/views/Admin/Stock/StockItems/list.vue'),
        meta: {
            permission: 'stock_items.list',
            pageTitle: 'navigations.stockItems',
            breadcrumb: [
                {
                    text: 'navigations.stockItems',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/stock-items/add',
        name: 'AddStockItem',
        component: () => import('@/views/Admin/Stock/StockItems/add.vue'),
        meta: {
            permission: 'stock_items.create',
            pageTitle: 'messages.stockItemsCreate',
            breadcrumb: [
                {
                    text: 'messages.stockItemsCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/stock-items/add-rotable',
        name: 'AddStockItemRotable',
        component: () => import('@/views/Admin/Stock/StockItems/addRotable.vue'),
        meta: {
            permission: 'stock_items.create',
            pageTitle: 'messages.stockItemsCreate',
            breadcrumb: [
                {
                    text: 'messages.stockItemsCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/stock-items/:id/edit',
        name: 'EditStockItem',
        component: () => import('@/views/Admin/Stock/StockItems/edit.vue'),
        meta: {
            permission: 'stock_items.view',
            pageTitle: 'messages.stockItemsUpdate',
            breadcrumb: [
                {
                    text: 'messages.stockItemsUpdate',
                    active: true,
                },
            ],
        },

    },
    // Stock items End
    // Tool requests
    {
        path: '/tool-requests',
        name: 'ToolRequests',
        component: () => import('@/views/Admin/Stock/ToolRequests/list.vue'),
        meta: {
            permission: 'tool_requests.list',
            pageTitle: 'navigations.toolRequests',
            breadcrumb: [
                {
                    text: 'navigations.toolRequests',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tool-requests/add',
        name: 'AddToolRequest',
        component: () => import('@/views/Admin/Stock/ToolRequests/add.vue'),
        meta: {
            permission: 'tool_requests.create',
            pageTitle: 'messages.toolRequestsCreate',
            breadcrumb: [
                {
                    text: 'messages.toolRequestsCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tool-requests/:id/edit',
        name: 'EditToolRequest',
        component: () => import('@/views/Admin/Stock/ToolRequests/edit.vue'),
        meta: {
            permission: 'tool_requests.view',
            pageTitle: 'messages.toolRequestsUpdate',
            breadcrumb: [
                {
                    text: 'messages.toolRequestsUpdate',
                    active: true,
                },
            ],
        },

    },
    // Tool requests End
    // Storage locations
    {
        path: '/storage-locations',
        name: 'StorageLocations',
        component: () => import('@/views/Admin/Stock/StorageLocations/list.vue'),
        meta: {
            permission: 'stock_item_locations.list',
            pageTitle: 'navigations.stockItemLocations',
            breadcrumb: [
                {
                    text: 'navigations.stockItemLocations',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/storage-locations/add',
        name: 'StorageLocationsAdd',
        component: () => import('@/views/Admin/Stock/StorageLocations/add.vue'),
        meta: {
            permission: 'stock_item_locations.create',
            pageTitle: 'messages.stockItemLocationsCreate',
            breadcrumb: [
                {
                    text: 'messages.stockItemLocationsCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/storage-locations/:id/edit',
        name: 'StorageLocationsEdit',
        component: () => import('@/views/Admin/Stock/StorageLocations/edit.vue'),
        meta: {
            permission: 'stock_item_locations.view',
            pageTitle: 'messages.stockItemLocationsUpdate',
            breadcrumb: [
                {
                    text: 'messages.stockItemLocationsUpdate',
                    active: true,
                },
            ],
        },

    },
    // Storage locations End
    // Stock item owner
    {
        path: '/stock-item-owners',
        name: 'StockItemOwner',
        component: () => import('@/views/Admin/Stock/StockItemOwner/list.vue'),
        meta: {
            permission: 'stock_item_owners.list',
            pageTitle: 'navigations.stockItemOwners',
            breadcrumb: [
                {
                    text: 'navigations.stockItemOwners',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/stock-item-owners/add',
        name: 'StockItemOwnerAdd',
        component: () => import('@/views/Admin/Stock/StockItemOwner/add.vue'),
        meta: {
            permission: 'stock_item_owners.create',
            pageTitle: 'messages.stockItemOwnerCreate',
            breadcrumb: [
                {
                    text: 'messages.stockItemOwnerCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/stock-item-owners/:id/edit',
        name: 'StockItemOwnerEdit',
        component: () => import('@/views/Admin/Stock/StockItemOwner/edit.vue'),
        meta: {
            permission: 'stock_item_owners.view',
            pageTitle: 'messages.stockItemOwnerUpdate',
            breadcrumb: [
                {
                    text: 'messages.stockItemOwnerUpdate',
                    active: true,
                },
            ],
        },

    },
    // Stock item owner End
    // Stock item type
    {
        path: '/stock-item-types',
        name: 'StockItemType',
        component: () => import('@/views/Admin/Stock/StockItemType/list.vue'),
        meta: {
            permission: 'stock_item_types.list',
            pageTitle: 'navigations.stockItemTypes',
            breadcrumb: [
                {
                    text: 'navigations.stockItemTypes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/stock-item-types/add',
        name: 'StockItemTypeAdd',
        component: () => import('@/views/Admin/Stock/StockItemType/add.vue'),
        meta: {
            permission: 'stock_item_types.create',
            pageTitle: 'messages.stockItemTypeCreate',
            breadcrumb: [
                {
                    text: 'messages.stockItemTypeCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/stock-item-types/:id/edit',
        name: 'StockItemTypeEdit',
        component: () => import('@/views/Admin/Stock/StockItemType/edit.vue'),
        meta: {
            permission: 'stock_item_types.view',
            pageTitle: 'messages.stockItemTypeUpdate',
            breadcrumb: [
                {
                    text: 'messages.stockItemTypeUpdate',
                    active: true,
                },
            ],
        },

    },
    // Stock item type End
    // Used items
    {
        path: '/used-items',
        name: 'UsedItems',
        component: () => import('@/views/Admin/Stock/UsedItems/list.vue'),
        meta: {
            permission: 'used_items.list',
            pageTitle: 'navigations.usedItems',
            breadcrumb: [
                {
                    text: 'navigations.usedItems',
                    active: true,
                },
            ],
        },
    },
    // Used items End
    {
        path: '/deliveries',
        name: 'Deliveries',
        component: () => import('@/views/Admin/Stock/Deliveries/list.vue'),
        meta: {
            permission: 'deliveries.list',
            pageTitle: 'navigations.deliveries',
            breadcrumb: [
                {
                    text: 'navigations.deliveries',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/deliveries/add',
        name: 'DeliveriesAdd',
        component: () => import('@/views/Admin/Stock/Deliveries/add.vue'),
        meta: {
            permission: 'deliveries.create',
            pageTitle: 'messages.deliveryCreate',
            breadcrumb: [
                {
                    text: 'messages.deliveryCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/deliveries/:id/edit',
        name: 'DeliveriesEdit',
        component: () => import('@/views/Admin/Stock/Deliveries/edit.vue'),
        meta: {
            permission: 'deliveries.view',
            pageTitle: 'messages.deliveryUpdate',
            breadcrumb: [
                {
                    text: 'messages.deliveryUpdate',
                    active: true,
                },
            ],
        },

    },
    // Deliveries End
    // OCR
    {
        path: '/ocr',
        name: 'OCR',
        component: () => import('@/views/Admin/Forms/Ocr/list.vue'),
        meta: {
            permission: 'ocr.list',
            pageTitle: 'navigations.ocr',
            breadcrumb: [
                {
                    text: 'navigations.ocr',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/ocr/add',
        name: 'OCRAdd',
        component: () => import('@/views/Admin/Forms/Ocr/add.vue'),
        meta: {
            permission: 'ocr.create',
            pageTitle: 'messages.ocrCreate',
            breadcrumb: [
                {
                    text: 'messages.ocrCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/ocr/:id/edit',
        name: 'OCREdit',
        component: () => import('@/views/Admin/Forms/Ocr/edit.vue'),
        meta: {
            permission: 'ocr.view',
            pageTitle: 'messages.ocrUpdate',
            breadcrumb: [
                {
                    text: 'messages.ocrUpdate',
                    active: true,
                },
            ],
        },

    },
    // OCR End
    // CWO
    {
        path: '/cwo',
        name: 'CWO',
        component: () => import('@/views/Admin/Forms/Cwo/list.vue'),
        meta: {
            permission: 'cwo.list',
            pageTitle: 'navigations.cwo',
            breadcrumb: [
                {
                    text: 'navigations.cwo',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cwo/add',
        name: 'CWOAdd',
        component: () => import('@/views/Admin/Forms/Cwo/add.vue'),
        meta: {
            permission: 'cwo.create',
            pageTitle: 'messages.cwoCreate',
            breadcrumb: [
                {
                    text: 'messages.cwoCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cwo/:id/edit',
        name: 'CWOEdit',
        component: () => import('@/views/Admin/Forms/Cwo/edit.vue'),
        meta: {
            permission: 'cwo.view',
            pageTitle: 'messages.cwoUpdate',
            breadcrumb: [
                {
                    text: 'messages.cwoUpdate',
                    active: true,
                },
            ],
        },

    },
    // CWO End
    // Analytics
    {
        path: '/stock-analytics',
        name: 'StockAnalytics',
        component: () => import('@/views/Admin/Stock/Analytics/list.vue'),
        meta: {
            permission: 'stock_analytics.list',
            pageTitle: 'forms.analytics',
            breadcrumb: [
                {
                    text: 'forms.analytics',
                    active: true,
                },
            ],
        },
    },
    // Roles
    {
        path: '/roles',
        name: 'RolesList',
        component: () => import('@/views/Admin/Roles/list.vue'),
        meta: {
            permission: 'roles.list',
            pageTitle: 'navigations.roles',
            breadcrumb: [
                {
                    text: 'navigations.roles',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/roles/add',
        name: 'RolesAdd',
        component: () => import('@/views/Admin/Roles/add.vue'),
        meta: {
            permission: 'roles.create',
            pageTitle: 'messages.roleCreate',
            breadcrumb: [
                {
                    text: 'messages.roleCreate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/roles/:id/edit',
        name: 'RoleEdit',
        component: () => import('@/views/Admin/Roles/edit.vue'),
        meta: {
            permission: 'roles.view',
            pageTitle: 'messages.roleUpdate',
            breadcrumb: [
                {
                    text: 'messages.roleUpdate',
                    active: true,
                },
            ],
        },

    },
    // CWO End
    // User profile
    {
        path: '/profile',
        name: 'EditProfile',
        component: () => import('@/views/Admin/UserProfile/edit.vue'),
        meta: {
            permission: 'user.profile',
            pageTitle: 'messages.userUpdate',
            breadcrumb: [
                {
                    text: 'messages.userUpdate',
                    active: true,
                },
            ],
        },
    },
    // User profile end
    // Emails settings
    {
        path: '/settings/emails',
        name: 'EmailTemplates',
        component: () => import('@/views/Admin/Settings/EmailTemplates/list.vue'),
        meta: {
            permission: 'email_templates.list',
            pageTitle: 'navigations.emailTemplates',
            breadcrumb: [
                {
                    text: 'navigations.emailTemplates',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings/emails/:id/edit',
        name: 'EditEmailTemplate',
        component: () => import('@/views/Admin/Settings/EmailTemplates/edit.vue'),
        meta: {
            permission: 'email_templates.view',
            pageTitle: 'messages.emailTemplateUpdate',
            breadcrumb: [
                {
                    text: 'messages.emailTemplateUpdate',
                    active: true,
                },
            ],
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    // Redirect to login if not logged in
    if (!isLoggedIn && (to.name !== 'login' && to.name !== 'forgot-password')) {
        return next({name: 'login'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
    }

    // Router guards
    if (isLoggedIn) {
        const userData = getUserData()
        if (userData.role !== 'ROLE_ADMIN') {
            if (to.meta.permission && to.meta.permission !== 'user.profile') {
                const splitTo = to.meta.permission.split('.')
                if (!userData.permissions[splitTo[0]][splitTo[1]]) {
                    const toast = useToast()
                    toast({
                        component: Toast,
                        position: 'top-right',
                        props: {
                            title: 'Access denied',
                            text: "You don't have permisson to access this page.",
                            variant: "danger",
                        },
                    })
                    return next({path: '/'})
                }
            }
        }
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
