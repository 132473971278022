<template>
    <div class="layout-vertical" :class="$route.name">
        <Loader v-if="$store.state.loading" />
        <NavBar :navbar-active="navbarActive" @navToggle="setNavVisible"/>
        <div class="app-container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue'
import Loader from "@/components/Loader.vue";

export default {
    components: {
        Loader,
        NavBar
    },
    data() {
        return {
            navbarActive: false
        }
    },
    methods: {
        setNavVisible(data) {
            this.navbarActive = data
        }
    }
}
</script>
