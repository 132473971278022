import { createApp } from 'vue'
import axios from 'axios'
import JwtService from './jwtService'

const axiosIns = axios.create({})
const app = createApp({})
app.config.globalProperties = axiosIns

const JWT = new JwtService(axiosIns)
export default JWT
