
import { createStore } from 'vuex'

export default createStore({
    state: {
        appName: 'Admin',
        apiUrl: `https://${process.env.VUE_APP_API_URL}`,
        defaultPerPage: 15,
        loading: false
    },
    mutations: {
        setLoading (state, loading) {
            state.loading = loading
        },
    },
    actions: {
    },
    modules: {
    }
})
